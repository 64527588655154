

















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import JobAdList from '@/views/company/JobAdList.vue'

@Component({
  components: { JobAdList }
})
export default class CandidateSearch extends Vue {
}
